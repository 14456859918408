import { Container, Header, Navbar, Nav, Content, Loader, Footer } from "rsuite";
import { useContext } from "react";
import AuthContext from "./AuthContext";
import ExitIcon from '@rsuite/icons/Exit';

const Layout = ({ children }) => {
  const { user, logout, version, company, loading } = useContext(AuthContext);

  return (
    <div className={`show-fake-browser${!user?' login-page':''}`}>
    <Container>
      <Header>
        <Navbar appearance="inverse">
          <Navbar.Brand>
            Автоматизация переключения камер
          </Navbar.Brand>
          {user && (<Nav pullRight>
            <Nav.Item>{user?.username}</Nav.Item>
            <Nav.Item>{company}</Nav.Item>
            <Nav.Item icon={<ExitIcon />} onClick={() => {logout();}}>Выход</Nav.Item>
          </Nav>)}
        </Navbar>
      </Header>
      <Content>{children}</Content>
      <Footer><div>{version}</div><div>&copy; 2023</div></Footer>
    </Container>
    {loading && <Loader backdrop content="Загрузка данных..." vertical />}
    </div>
  );
};

export default Layout;