import axios from "axios";
import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Notification, useToaster } from "rsuite";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const toaster = useToaster();
  const erraccess = (
    <Notification type="error" header="Ошибка">Неправильный логин или пароль</Notification>
  );
  const errroles = (
    <Notification type="error" header="Ошибка">Нет прав для домена</Notification>
  );

  const [user, setUser] = useState(() => {
    if (localStorage.getItem("tokens")) {
      let tokens = JSON.parse(localStorage.getItem("tokens"));
      return jwt_decode(tokens.access_token);
    }
    return null;
  });

  const [token, setToken] = useState(() => {
    if (localStorage.getItem("tokens")) {
      let tokens = JSON.parse(localStorage.getItem("tokens"));
      return tokens.access_token;
    }
    return null;
  });
 
  const domain = process.env.NODE_ENV === "production" ? window.location.hostname.split('.')[0] : 'spb03'

  const [version, setVersion] = useState('')
  const [company, setCompany] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const login = async (payload) => {
    try {
      const apiResponse = await axios.post(
        "https://www.cameracontrol.ru/api/login",
        payload
      );
      const roles = jwt_decode(apiResponse.data.access_token).rls.split(',')
      if (roles.indexOf(domain) >= 0) {
        localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
        setUser(jwt_decode(apiResponse.data.access_token));
        navigate("/");
      } else {
        const error = new Error('Access denied');
        error.code = 401;
        error.message = 'Access denied';
        throw error;
      }
    } catch (error) {
      if (error?.response?.data?.message === 'The username and/or password are incorrect') {
        toaster.push(erraccess, {placement: 'bottomEnd'})
      } else if (error?.message === 'Access denied') {
        toaster.push(errroles, {placement: 'bottomEnd'})
      }
    }
  };
  const logout = async () => {
    localStorage.removeItem("tokens");
    setUser(null);
    setToken(null);
    navigate("/login");
  };
  return (
    <AuthContext.Provider value={{ user, token, login, logout, version, setVersion, company, setCompany, loading, setLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;