import { useContext, useRef } from "react";
import { FlexboxGrid, Panel, Form, ButtonToolbar, Button } from "rsuite";
import AuthContext from "../components/shared/AuthContext";

const Login = () => {
  const userName = useRef("");
  const password = useRef("");
  const {login}= useContext(AuthContext)

  const loginSubmit = async () => {
    let payload = {
      username: userName.current.value,
      password: password.current.value
    }
    await login(payload);
  };

  return (
    <>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={12}>
          <Panel header={<h3>Автоматизация</h3>} bordered>
            <Form fluid>
              <Form.Group controlId="formUserName">
                <Form.ControlLabel>Имя пользователя</Form.ControlLabel>
                <Form.Control type="text" name="userName" inputRef={userName} />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.ControlLabel>Пароль</Form.ControlLabel>
                <Form.Control type="password" name="password" inputRef={password} />
              </Form.Group>
              <Form.Group>
                <ButtonToolbar>
                  <Button type='submit' appearance="primary" onClick={loginSubmit}>Войти</Button>
                </ButtonToolbar>
              </Form.Group>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};
export default Login;